import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"outlined":""}},[_c(VCardTitle,[_vm._v(_vm._s(_vm.$t("seminars.seminarList.yourSemis")))]),_c(VCardSubtitle,[_vm._v(" "+_vm._s(_vm.$t("seminars.seminarList.participantOrOwnerOf"))+" ")]),_c(VCardText,[_c(VList,{staticStyle:{"overflow":"auto"},attrs:{"max-height":"240px"}},_vm._l((_vm.seminars),function(s,i){return _c(VListItem,{key:i,on:{"click":function($event){return _vm.$router.push('/seminars/dashboard/' + s.id)}}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(s.name))]),_c(VListItemSubtitle,[_vm._v(_vm._s(s.description))]),_c(VListItemSubtitle,[_c('span',[_vm._v(_vm._s(_vm._f("formatwithtime")(s.createdAt)))]),_vm._v(" · "),_c('span',[_vm._v(_vm._s(s.ownerId === _vm.userId ? "Owner" : "Participant"))])])],1),(s.logo)?_c(VListItemAvatar,[_c(VImg,{attrs:{"src":s.logo}})],1):_vm._e()],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }